import { render, staticRenderFns } from "./default.vue?vue&type=template&id=9b65633a"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuLanguage: require('/var/www/web134/htdocs/lpm.pl-x.de/deployer/releases/20241111141119/frontend/components/menu/Language.vue').default,MenuTenant: require('/var/www/web134/htdocs/lpm.pl-x.de/deployer/releases/20241111141119/frontend/components/menu/Tenant.vue').default,MenuAdmin: require('/var/www/web134/htdocs/lpm.pl-x.de/deployer/releases/20241111141119/frontend/components/menu/Admin.vue').default,MenuUser: require('/var/www/web134/htdocs/lpm.pl-x.de/deployer/releases/20241111141119/frontend/components/menu/User.vue').default})
