import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ceb7e83c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _b363d5da = () => interopDefault(import('../pages/auth/forgotten.vue' /* webpackChunkName: "pages/auth/forgotten" */))
const _93c8913c = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _d592fb70 = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _e3306aca = () => interopDefault(import('../pages/auth/welcome.vue' /* webpackChunkName: "pages/auth/welcome" */))
const _98cdffda = () => interopDefault(import('../modules/users/pages/index.vue' /* webpackChunkName: "" */))
const _318965ea = () => interopDefault(import('../modules/users/pages/create.vue' /* webpackChunkName: "" */))
const _774954d9 = () => interopDefault(import('../modules/users/pages/edit.vue' /* webpackChunkName: "" */))
const _aeaa3eb2 = () => interopDefault(import('../modules/stores/pages/index.vue' /* webpackChunkName: "" */))
const _76ef11c5 = () => interopDefault(import('../modules/stores/pages/edit.vue' /* webpackChunkName: "" */))
const _2ec5b322 = () => interopDefault(import('../modules/landingPages/pages/index.vue' /* webpackChunkName: "" */))
const _5cb60848 = () => interopDefault(import('../modules/landingPages/pages/create.vue' /* webpackChunkName: "" */))
const _6237a958 = () => interopDefault(import('../modules/landingPages/pages/create/base.vue' /* webpackChunkName: "" */))
const _358dabe4 = () => interopDefault(import('../modules/landingPages/pages/create/final-order-confirmation.vue' /* webpackChunkName: "" */))
const _58894202 = () => interopDefault(import('../modules/landingPages/pages/create/cross-sell.vue' /* webpackChunkName: "" */))
const _63390185 = () => interopDefault(import('../modules/landingPages/pages/create/sepa-enhancement.vue' /* webpackChunkName: "" */))
const _3dc7bfa0 = () => interopDefault(import('../modules/landingPages/pages/create/upsell.vue' /* webpackChunkName: "" */))
const _680dc6f0 = () => interopDefault(import('../modules/landingPages/pages/create/video.vue' /* webpackChunkName: "" */))
const _afa6f428 = () => interopDefault(import('../modules/landingPages/pages/create/email-signup.vue' /* webpackChunkName: "" */))
const _88f0f582 = () => interopDefault(import('../modules/landingPages/pages/create/webinar-registration.vue' /* webpackChunkName: "" */))
const _beb11e34 = () => interopDefault(import('../modules/landingPages/pages/create/free-signup.vue' /* webpackChunkName: "" */))
const _1d19c295 = () => interopDefault(import('../modules/landingPages/pages/create/multi-offer.vue' /* webpackChunkName: "" */))
const _0f0e1778 = () => interopDefault(import('../modules/landingPages/pages/create/appointment-booking.vue' /* webpackChunkName: "" */))
const _14e85672 = () => interopDefault(import('../modules/credentials/pages/index.vue' /* webpackChunkName: "" */))
const _317c31d5 = () => interopDefault(import('../modules/credentials/pages/new.vue' /* webpackChunkName: "" */))
const _0e0f19a5 = () => interopDefault(import('../modules/credentials/pages/edit.vue' /* webpackChunkName: "" */))
const _0d1e9348 = () => interopDefault(import('../modules/fileManager/pages/index.vue' /* webpackChunkName: "" */))
const _03825a54 = () => interopDefault(import('../modules/tenants/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _ceb7e83c,
    name: "index___en"
  }, {
    path: "/auth/forgotten",
    component: _b363d5da,
    name: "auth-forgotten___de"
  }, {
    path: "/auth/login",
    component: _93c8913c,
    name: "auth-login___de"
  }, {
    path: "/auth/reset",
    component: _d592fb70,
    name: "auth-reset___de"
  }, {
    path: "/auth/welcome",
    component: _e3306aca,
    name: "auth-welcome___de"
  }, {
    path: "/en/auth/forgotten",
    component: _b363d5da,
    name: "auth-forgotten___en"
  }, {
    path: "/en/auth/login",
    component: _93c8913c,
    name: "auth-login___en"
  }, {
    path: "/en/auth/reset",
    component: _d592fb70,
    name: "auth-reset___en"
  }, {
    path: "/en/auth/welcome",
    component: _e3306aca,
    name: "auth-welcome___en"
  }, {
    path: "/",
    component: _ceb7e83c,
    name: "index___de"
  }, {
    path: "/users",
    component: _98cdffda,
    name: "users___de"
  }, {
    path: "/en/users",
    component: _98cdffda,
    name: "users___en"
  }, {
    path: "/users/create",
    component: _318965ea,
    name: "users-create___de"
  }, {
    path: "/en/users/create",
    component: _318965ea,
    name: "users-create___en"
  }, {
    path: "/users/edit",
    component: _774954d9,
    name: "users-edit___de"
  }, {
    path: "/en/users/edit",
    component: _774954d9,
    name: "users-edit___en"
  }, {
    path: "/stores",
    component: _aeaa3eb2,
    name: "stores___de"
  }, {
    path: "/en/stores",
    component: _aeaa3eb2,
    name: "stores___en"
  }, {
    path: "/stores/edit",
    component: _76ef11c5,
    name: "stores-edit___de"
  }, {
    path: "/en/stores/edit",
    component: _76ef11c5,
    name: "stores-edit___en"
  }, {
    path: "/landingpages",
    component: _2ec5b322,
    name: "landingpages___de"
  }, {
    path: "/en/landingpages",
    component: _2ec5b322,
    name: "landingpages___en"
  }, {
    path: "/landingpages/create",
    component: _5cb60848,
    name: "landingpages-create___de"
  }, {
    path: "/en/landingpages/create",
    component: _5cb60848,
    name: "landingpages-create___en"
  }, {
    path: "/landingpages/create/base",
    component: _6237a958,
    name: "landingpages-create-base___de"
  }, {
    path: "/en/landingpages/create/base",
    component: _6237a958,
    name: "landingpages-create-base___en"
  }, {
    path: "/landingpages/create/final-order-confirmation",
    component: _358dabe4,
    name: "landingpages-create-final-order-confirmation___de"
  }, {
    path: "/en/landingpages/create/final-order-confirmation",
    component: _358dabe4,
    name: "landingpages-create-final-order-confirmation___en"
  }, {
    path: "/landingpages/create/cross-sell",
    component: _58894202,
    name: "landingpages-create-cross-sell___de"
  }, {
    path: "/en/landingpages/create/cross-sell",
    component: _58894202,
    name: "landingpages-create-cross-sell___en"
  }, {
    path: "/landingpages/create/sepa-enhancement",
    component: _63390185,
    name: "landingpages-create-sepa-enhancement___de"
  }, {
    path: "/en/landingpages/create/sepa-enhancement",
    component: _63390185,
    name: "landingpages-create-sepa-enhancement___en"
  }, {
    path: "/landingpages/create/upsell",
    component: _3dc7bfa0,
    name: "landingpages-create-upsell___de"
  }, {
    path: "/en/landingpages/create/upsell",
    component: _3dc7bfa0,
    name: "landingpages-create-upsell___en"
  }, {
    path: "/landingpages/create/video",
    component: _680dc6f0,
    name: "landingpages-create-video___de"
  }, {
    path: "/en/landingpages/create/video",
    component: _680dc6f0,
    name: "landingpages-create-video___en"
  }, {
    path: "/landingpages/create/email-signup",
    component: _afa6f428,
    name: "landingpages-create-email-signup___de"
  }, {
    path: "/en/landingpages/create/email-signup",
    component: _afa6f428,
    name: "landingpages-create-email-signup___en"
  }, {
    path: "/landingpages/create/webinar-registration",
    component: _88f0f582,
    name: "landingpages-create-webinar-registration___de"
  }, {
    path: "/en/landingpages/create/webinar-registration",
    component: _88f0f582,
    name: "landingpages-create-webinar-registration___en"
  }, {
    path: "/landingpages/create/free-signup",
    component: _beb11e34,
    name: "landingpages-create-free-signup___de"
  }, {
    path: "/en/landingpages/create/free-signup",
    component: _beb11e34,
    name: "landingpages-create-free-signup___en"
  }, {
    path: "/landingpages/create/multi-offer",
    component: _1d19c295,
    name: "landingpages-create-multi-offer___de"
  }, {
    path: "/en/landingpages/create/multi-offer",
    component: _1d19c295,
    name: "landingpages-create-multi-offer___en"
  }, {
    path: "/landingpages/create/appointment-booking",
    component: _0f0e1778,
    name: "landingpages-create-appointment-booking___de"
  }, {
    path: "/en/landingpages/create/appointment-booking",
    component: _0f0e1778,
    name: "landingpages-create-appointment-booking___en"
  }, {
    path: "/credentials",
    component: _14e85672,
    name: "credentials___de"
  }, {
    path: "/en/credentials",
    component: _14e85672,
    name: "credentials___en"
  }, {
    path: "/credentials/new",
    component: _317c31d5,
    name: "credentials-new___de"
  }, {
    path: "/en/credentials/new",
    component: _317c31d5,
    name: "credentials-new___en"
  }, {
    path: "/credentials/edit",
    component: _0e0f19a5,
    name: "credentials-edit___de"
  }, {
    path: "/en/credentials/edit",
    component: _0e0f19a5,
    name: "credentials-edit___en"
  }, {
    path: "/filemanager",
    component: _0d1e9348,
    name: "filemanager___de"
  }, {
    path: "/en/filemanager",
    component: _0d1e9348,
    name: "filemanager___en"
  }, {
    path: "/tenants",
    component: _03825a54,
    name: "tenants___de"
  }, {
    path: "/en/tenants",
    component: _03825a54,
    name: "tenants___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
